import { useIsTablet } from 'src/hooks/use-is-mobile';
import { imageOptimize } from 'src/service/image';
import Carousel from 'nuka-carousel';
import Link from 'next/link';
import { useState } from 'react';
import { HStack, Box, StackProps } from '@chakra-ui/react';
// import CrossfadeImage from 'react-crossfade-responsive';

const Banners = ({ data }: { data: any[] }) => {
  const isMobile = useIsTablet();
  const [carouselIdx, setCarouselIndx] = useState<number>();

  const getMobile = (tData, tData1) => {
    if (isMobile) {
      return tData;
    }
    return tData1;
  };

  return (
    <div className="relative">
      <div className="text-gray-900 flex w-full mx-auto">
        <Carousel
          slidesToShow={isMobile ? 1 : 3}
          cellSpacing={48}
          autoplay
          wrapAround
          afterSlide={(slideIndex) => setCarouselIndx(slideIndex)}
          transitionMode={getMobile('scroll3d', 'scroll')}
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          defaultControlsConfig={{
            pagingDotsContainerClassName: `${
              isMobile ? 'bannerDots' : 'noBanner'
            }`,
            pagingDotsClassName: 'px-0.5',
            pagingDotsStyle: {
              fill: '#8C8F9B',
            },
          }}
        >
          {data.map((d, key) => {
            const mkey = `banner_${key}`;
            return <BannerCard key={mkey} index={mkey} data={d} />;
          })}
        </Carousel>
        <style jsx global>{`
          .bannerDots {
            top: 50px !important;
          }
          .noBanner {
            display: none !important;
          }
        `}</style>
      </div>
      <div
        className={`${
          isMobile && 'hidden'
        } absolute left-0 right-0 m-auto w-min`}
      >
        <Dots arrayData={data} activeIndex={carouselIdx} />
      </div>
    </div>
  );
};
export default Banners;

function Dots({
  arrayData,
  as,
  activeIndex,
  ...props
}: { arrayData: any[]; activeIndex?: number } & StackProps) {
  return (
    <HStack spacing="6px" {...props}>
      {arrayData?.map((d, i) => (
        <Box
          key={d.title}
          width="6px"
          height="6px"
          borderRadius="3px"
          as={as}
          bg={activeIndex === i ? 'gray.600' : 'gray.300'}
        />
      ))}
    </HStack>
  );
}

const BannerCard = ({ data, index }: { data: any; index: string }) => {
  const isMobile = useIsTablet();
  return (
    <Link prefetch={false} href={`${data?.destination_url}`}>
      <div className="cursor-pointer">
        <div
          style={{ height: `${isMobile ? '210px' : '369px'}` }}
          className={`${index}-box ${
            isMobile ? '' : 'rounded-3xl'
          } overflow-hidden`}
        />
        <div className="text-gray-900 font-medium text-sm p-5 banner-card-gradient">
          <div>{data.subtitle}</div>
          <div className="font-semibold text-xl md:text-2xl py-1 pb-3">
            {data.title}
          </div>
          <div className="text-gray-500 line-clamp-2">{data.description}</div>
        </div>
        <style jsx global>{`
          .${index}-box {
            background: url('${imageOptimize(data?.image_url)}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
          .banner-card-gradient {
            background: linear-gradient(
              rgba(241, 242, 242, 1),
              rgba(232, 234, 235, 1)
            );
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
        `}</style>
      </div>
    </Link>
  );
};
